import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import Nav from '../components/Nav';
import Header from '../components/Header';

const HomePageStyles = styled.div`
  .homepage-grid {
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    //max-width: 700px;
    //background-color: white;
    //box-shadow: 1px 1px 2px 2px whitesmoke;
    display: grid;
    gap: 4rem;
    grid-template-columns: 40% 60%;
    //background-color: var(--color_0);
    @media (max-width: 800px) {
      width: 90%;
      grid-template-columns: auto;
      gap: 1rem;
    }

    .picture-container {
      padding-top: 2rem;
      padding-bottom: 2rem;
      width: 40%;
      justify-self: end;
      align-self: center;
      @media (max-width: 800px) {
        padding-bottom: 1rem;
        max-height: 200px;
        max-width: 200px;
        justify-self: center;
      }
    }

    .gatsby-image-wrapper {
      max-height: 200px;
      max-width: 200px;
      border-radius: 50%;
    }

    .text-container {
      //font-size: 3rem;
      padding-top: 2rem;
      max-width: 85%;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h2 {
        font-style: italic;
        //font-weight: 500;
      }
      p {
        width: 100%;
        margin-bottom: 0;
      }
      @media (max-width: 800px) {
        display: block;
        justify-self: center;
        padding: 2rem;
        text-align: center;
        min-height: 0;
        p {
          width: 100%;
        }
      }
    }
  }

  .texto-index {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .container-texto-2 {
    background-color: var(--color_8);
    p{
      max-width: 700px;
      margin: 0 auto;
      padding-top: 15px;
      padding-bottom: 15px;
      //background-color: white;
      //box-shadow: 1px 1px 2px 2px whitesmoke;

      //background-color: var(--color_5);

      @media (max-width: 800px) {
        width: 90%;
        margin-top: 1rem;
        text-align: center;
      }

    }

  }

  .container-cita {
    
    p {
      margin: 0 auto;
      margin-top: 4rem;
      margin-bottom: 4rem;
      max-width: 700px;
      background-color: white;
      //box-shadow: 1px 1px 2px 2px whitesmoke;

      @media (max-width: 800px) {
        width: 90%;
        margin-top: 1rem;
      }
    }
  }
`;

export default function HomePage({ data: { author, categorias } }) {
  return (
    <>
      <SEO title="Home" image={author.image?.asset?.fluid?.src} />
      <Header />
      <Nav categorias={categorias.nodes}/>
      <HomePageStyles>
        <div className="homepage-grid">
          <div className="picture-container">
            <Img fluid={author.image.asset.fluid} />
          </div>
          <div className="text-container">
            <h2>Hola! Soy Laura Milberg.</h2>
            <p>
            Psicóloga clínica - UBA.
            <br/>
            <br/>
            Te ofrezco un espacio de escucha para pensar y construir juntos.
            <br/>
            <br/>
            Transformar aquello que hace sufrir.
            </p>
          </div>

        </div>
        <div className="container-texto-2">
          <p>
            Te propongo un espacio para hablar, pensar, cuestionar aquello que te está incomodando.
            <br/>
            <span><div className="hand-drawn-divider-1"></div></span>
            <br/>
            Tal vez estés sostiendo más peso que el querés llevar... puede que algo te este haciendo sufrir o te sientas frustradx.
            <br/>
            <br/>
            Intentaremos, entonces, construir algo nuevo.
          </p>
        </div>
        <div className="center texto-index container-cita">
          <p className="cita">
            "Somos seres con la capacidad de desear pero siempre incompletos, de ahí surge nuestro caminar."
            <br/>
            <br/>
            Jacques Lacan
          </p>
        </div>
      </HomePageStyles>
    </>
  );
}

export const query = graphql`
  query {
    author: sanityAuthor(name: {eq: "Lic. Laura Milberg"}) {
      id
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    categorias: allSanityCategory {
      nodes {
        title
      }
    }
  }
`;